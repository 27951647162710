//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  head() {
    return {
      title: this.$t("metaTitles.login"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.$t("metaDescriptions.login"),
        },
      ],
    };
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  middleware: 'guest',
    methods: {
      async login() {
        try {

          this.$auth.loginWith('local', {
            data: {
              email: this.email,
              password: this.password
            }
          }).catch(e => {
            this.$toast.show({
                type: "danger",
                message: e,
              });
              return
          });

          this.$toast.show({
              type: "success",
              message: "You are logged in!",
            });
          this.$router.push(`/`);

        } catch (error) {
          console.log("Log in error:", error);
        }
      },
  },
};
